/**
* A widget to manage a list of strings.
* Initially made for course releases tasks.
*
* See admin.shared.field-list for the basic html needed for this
* widget to work properly.
*/

export default class ListWidget {
    /**
     * @param element jQuery|string jQuery object or css selector for the div containing the widget.
     */
    constructor(element) {
        if (element instanceof jQuery) {
            this.$el = element;
        } else {
            this.$el = $(element);
        }

        this.fieldName = this.$el.data('field-name');
        this.ignoreKeyCodes = [
            39, // Right
            38, // Up
            40, // Down
            37, // Left
            93, // Command
            18, // Alt
            17, // Control
            16, // Shift
            9, // Tab
            27, // ESC
            13, // Enter
        ];

        /**
         * Binds text input events to the thing.
         *
         * @param thing A jQuery object
         */

        // bind events on all current list items
        this.$el.find('.list-item').each((index, el) => {
            this.bindEvents($(el));
        });

        // bind to new item button
        this.$el.find('.list-actions a.list-new-item').on('click', (event) => {
            event.preventDefault();
            this.createNewItem();
        });
    }


    /**
     * Creates a new list item and focus on it or focus on the next
     * list item if there is already a new empty item at the bottom.
     *
     * @param sourceInput The input that triggered the creation
     */
    createNewItem(sourceInput) {
        const $lastInput = this.$el.find('input[type="text"]:last');
        if ($lastInput.length > 0 && $lastInput.val().trim() === '') {
            if (sourceInput) {
                sourceInput.parents('.list-item:eq(0)').next().find('input').focus();
            } else {
                $lastInput.focus();
            }

            return;
        }
        const html = [
            '<div class="list-item">',
            '    <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 list-item">',
            '        <div class="form-group">',
            `            <input type="text" class="form-control" name="${this.fieldName}[new][]" value="" />`,
            '        </div>',
            '    </div>',
            '    <div class="col-sm-1 col-md-1 col-lg-1">',
            '        <a href="" class="btn btn-danger btn-sm list-item-delete">X</a>',
            '    </div>',
            '</div>',
        ];
        const $item = $(html.join(''));
        this.$el.find('.list-items').append($item);
        this.bindEvents($item);
        const $input = this.$el.find('input[type="text"]:last');
        $input.focus();
    }

    bindEvents(thing) {
        thing.find('input[type="text"]').on('keydown', this.keydownHandler.bind(this));
        thing.find('input[type="text"]').on('keyup', this.keyupHandler.bind(this));
        thing.find('a.list-item-delete').on('click', this.deleteHandler.bind(this));
    }


    keydownHandler(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
        }
    }

    keyupHandler(event) {
        event.preventDefault();
        if (event.keyCode === 13 && $(event.currentTarget).val().trim() !== '') {
            $.proxy(this.createNewItem, this)($(event.currentTarget));
        }
        if ($.inArray(event.keyCode, this.ignoreKeyCodes) !== -1) {
            return;
        }
        const inputName = $(event.currentTarget).attr('name');
        if (inputName && inputName.match(/keep/)) {
            $(event.currentTarget).attr('name', inputName.replace(/keep/, 'update'));
        }
    }

    /**
     * Deletes a list item.
     *
     * @param event Delete button/link click event.
     */
    deleteHandler(event) {
        window.button = $(event.currentTarget);
        event.preventDefault();
        const $target = $(event.currentTarget).parents('.list-item:eq(0)');
        const $input = $target.find('input:eq(0)').clone();
        // only mark an item as deleted if is not new
        if ($input.attr('name').match(/keep|update/)) {
            $input.attr({
                type: 'hidden',
                name: $input.attr('name').replace(/keep|update/, 'delete'),
            }).appendTo(this.$el);
        }
        $target.fadeOut('fast', () => { $target.remove(); });
    }
}
