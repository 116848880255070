/**
 *
*/

export default class SubmissionFilter {
    constructor() {
        const $form = $('[role="filters"]');
        const $clear = $form.find('[data-filter="clear"]');
        const $dateFilters = $form.find('.date-filter');
        const $select = $form.find('.selectpicker');

        $select.selectpicker({
            size: 5,
            width: '400',
        });

        $dateFilters.each((index, el) => {
            $(el).datetimepicker({
                pickTime: false,
                format: 'YYYY-M-D',
                defaultDate: null,
            });
        });

        // Clear the form and reload
        $clear.on('click', () => {
            $select.selectpicker('val', '0');

            $dateFilters.each((index, item) => {
                $(item).data('DateTimePicker').setValue(null);
            });

            $form.get(0).submit();
        });
    }
}
