/*

    Name:           Socket Server
    Created:        23 June 2014
    Author:         Aarron
    Description:    Handles interactions with the server

*/
import _ from 'lodash';
import config from '../common/config';
import moment from 'moment';
import studentFeedTpl from '../templates/student-feed.html';

export default class SocketServer {
    intakeId = null;
    socketServer = {};
    connection = {};
    timeUpdateIntervals = 60000; // 1 minute
    loadingChatItems = false; // Has there been a request to load chat items
    /* User Object */
    user = {
        isBanned: false,
        /* Array of ids that the current user follows */
        followingUsers: [],
        followingUsersOnline: [],
    };

    constructor() {
        if (!config.WSERVER) {
            console.warn('Unable to find configuration details');

            return false;
        }

        /* jshint camelcase: false */
        const chatConfig = JSON.parse(window.atob(config.WSERVER)).event_server;
        const serverKey = chatConfig.key;

        /* Start new connection */
        if (window.WebSocket) {
            this.connection = new WebSocket(`${chatConfig.protocol}://${chatConfig.host}:${chatConfig.port}`);
        } else {
            config.mediator.publish(config.MEDIATOR.CONNECTION.NOT_SUPPORTED, 'Sorry, Student Hub is not available in this browser.');

            return false;
        }

        /* When socket is open */
        this.connection.onopen = () => {
            const payload = { event_server_key: serverKey };
            if (typeof (chatConfig.intake_id) !== 'undefined') {
                payload.intake_id = chatConfig.intake_id;
                this.intakeId = chatConfig.intake_id;
            }

            const event = {
                type: 'auth',
                payload: payload,
            };

            /* Send auth DEFAULT */
            this.connection.send(JSON.stringify(event));
        };


        /* Handle event messages */
        this.connection.onmessage = function(msg) {
            const event = JSON.parse(msg.data);
            const eventType = event.type || null;

            console.info(`WS:${eventType}`);

            if (eventType && this[eventType]) {
                this[eventType](event);
            } else {
                console.warn('Unexpected event');
            }
        };

        /* Handle a closed connection */
        this.connection.onclose = function() {
            /*
                Only trigger this if the user is not banned. The user
                had already recieved a notification that they have been banned. No
                need to tell them that the server is not responding.
            */
            if (!this.user.isBanned) {
                config.mediator.publish(config.MEDIATOR.CONNECTION.DEAD, 'Sorry, the server is not responding...');
            }
        };
    }


    /**
     * Populate template with data and publish.
     * @param {id} Number - message id used as reference point
     * @param {count} String - direction and count +20 or -20 etc
     * @returns {Array}
     */
    loadChatItems(id, direction) {
        if (!this.loadingChatItems) {
            this.loadingChatItems = true;

            const directionOperator = (direction === config.CHAT_DIR_BACK) ? '-' : '+';

            let endpoint = config.ENDPOINTS.MESSAGES_LOAD_NEXT;
            endpoint += `?message_id=${id}`;
            endpoint += `&count=${directionOperator}${config.BATCHLOADCOUNT}`;

            if (this.intakeId !== null) {
                endpoint += `&intake=${this.intakeId}`;
            }

            /* Always get a max of 20 */
            $.get(endpoint)

                .done((data) => {
                    if (data && data.success && data.payload) {
                        if (data.payload.messages) {
                            this.hydrateChatTemplate(data.payload.messages, direction, data.payload.messages.length, data.payload.remaining);
                            this.loadingChatItems = false;
                        }
                    } else {
                        this.loadingChatItems = false;
                    }
                })

                .fail(() => {
                    this.loadingChatItems = false;
                });
        }
    }

    getFollowingOnlineCount(connectionType, users) {
        if (!users || !connectionType) {
            return false;
        }

        /* Iterate over users provided and determine if the current user follows them */
        _.each(users, (userToProcess) => {
            if (this.user.followingUsers.indexOf(userToProcess.id) > -1) {
                // Current user follows this user
                if (connectionType === config.FOLLOWER_CONNECT) {
                    this.user.followingUsersOnline.push(userToProcess.id);
                } else {
                    /* Remove */
                    this.user.followingUsersOnline.splice(this.user.followingUsersOnline.indexOf(userToProcess.id), 1);
                }
            }
        });

        console.log('Users I follow online ', this.user.followingUsersOnline);

        return this.user.followingUsersOnline.length;
    }

    auth(event) {
        console.log('Client Authenticated:');
        console.log(event);

        /* User has auth'd successfully */
        if (event && _.isObject(event.payload)) {
            /* extend the base user */
            _.extend(this.user, event.payload);

            /* Get array of following ids */
            _.each(this.user.following, (follower) => {
                this.user.followingUsers.push(follower.id);

                if (follower.online) {
                    this.user.followingUsersOnline.push(follower.id);
                }
            });

            /* Announce that the connection is live */
            config.mediator.publish(config.MEDIATOR.CONNECTION.ALIVE, true);

            /* Announce users online */
            /* jshint camelcase: false*/
            config.mediator.publish(config.MEDIATOR.USERS.ONLINE, this.user.others_online, this.user.following_online);

            /* Announce notifications */
            config.mediator.publish(config.MEDIATOR.NOTIFICATION, this.user.notifications);

            /* Accept messages */
            config.mediator.subscribe(config.MEDIATOR.CHAT.SEND, (msg) => {
                this.sendMessage(msg);
            });

            /* Accept requests for a range of messages  */
            config.mediator.subscribe(config.MEDIATOR.CHAT.MESSAGES.GETRANGE, (id, direction) => {
                if (!this.loadingChatItems) {
                    this.loadChatItems(id, direction);
                }
            });

            /* Time updates */
            setInterval(() => {
                $('[data-toggle="chat-createdat"]').each((index, element) => {
                    const $chatTime = $(element);
                    $chatTime.text(moment($chatTime.data('createdat')).fromNow());
                }
                );
            }, this.timeUpdateIntervals);

            /* Is there any message history ? */
            if (this.user.messages.length > 0) {
                this.hydrateChatTemplate(this.user.messages, config.CHAT_DIR_FORWARD, event.payload.messages.length, event.payload.remaining);
            } else {
                // No messages
                config.mediator.publish(config.MEDIATOR.CHAT.MESSAGES.DEFAULT, false);
            }

            /* Is there any activity history ? */
            if (this.user.activity.length > 0) {
                this.hydrateActivityTemplate(this.user.activity);
            } else {
                config.mediator.publish(config.MEDIATOR.ACTIVITY, false);
            }

            /* Followers? */
            if (this.user.following.length > 0) {
                this.hydrateFollowerTemplate(this.user.following);
            } else {
                config.mediator.publish(config.MEDIATOR.FOLLOWER_ONLINE, false);
            }
        }
    }


    banned(event) {
        console.log('Banned:', event);

        this.user.isBanned = true;

        if (event && event.payload) {
            console.log(event.payload);
            config.mediator.publish(config.MEDIATOR.AUTH.IS_BANNED, event.payload.message);
        }
    }


    activity(event) {
        console.log('New Activity:', event);
        this.hydrateActivityTemplate(event.payload);
    }


    /* jshint camelcase: false */
    chat(event) {
        console.log('New Stream Entry:', event);

        if (event && event.payload) {
            this.hydrateChatTemplate(event.payload, config.CHAT_DIR_FORWARD, 1);
        }
    }


    connect(event) {
        console.log('New User Connection:', event);

        const followingOnline = this.getFollowingOnlineCount(config.FOLLOWER_CONNECT, event.payload.users);

        /* Announce a connect */
        this.user.others_online = event.payload.others_online;
        config.mediator.publish(config.MEDIATOR.USERS.ONLINE, this.user.others_online, followingOnline);
        config.mediator.publish(config.MEDIATOR.FOLLOWER_ONLINE, event.payload.users, config.FOLLOWER_CONNECT);
    }


    disconnect(event) {
        console.log('Users Have Disconnected:', event);

        const followingOnline = this.getFollowingOnlineCount(config.FOLLOWER_DISCONNECT, event.payload.users);

        /* Announce a disconnect */
        this.user.others_online = event.payload.others_online;
        config.mediator.publish(config.MEDIATOR.USERS.ONLINE, this.user.others_online, followingOnline);
        config.mediator.publish(config.MEDIATOR.FOLLOWER_ONLINE, event.payload.users, config.FOLLOWER_DISCONNECT);
    }


    /* Derp */
    error(event) {
        console.error(event.payload.message);
        config.mediator.publish(config.MEDIATOR.NOTIFICATION, 'An error occured');
    }


    /* Announce a mention */
    mention(event) {
        console.log('You were mentioned in the chat!', event);
        config.mediator.publish(config.MEDIATOR.CHAT.MESSAGES.MENTION, event.payload);
    }

    /* Announce a notification */
    notification(event) {
        console.log('A new notification was received!', event);
        config.mediator.publish(config.MEDIATOR.NOTIFICATION, event.payload);
    }


    /* Populate Followers template*/
    hydrateFollowerTemplate(followerList) {
        const templateData = {
            followers: [],
            templateType: 'followers',
        };

        // Is there multiple items?
        if (_.isArray(followerList)) {
            templateData.followers = followerList;
        } else {
            // single follower
            templateData.followers.push(followerList);
        }

        /* Publish */
        if (templateData.followers.length > 0) {
            config.mediator.publish(config.MEDIATOR.FOLLOWER_ONLINE, _.template(studentFeedTpl, templateData), config.FOLLOWER_SET);
        }
    }


    /* Populate activity template with data and publish */
    hydrateActivityTemplate(activityList) {
        const templateData = {
            activities: [],
            templateType: 'activity',
            activityTerms: config.ACTIVITY_TERMS,
        };

        // Is there multiple items?
        if (_.isArray(activityList)) {
            templateData.activities = activityList;
        } else {
            // single activity
            templateData.activities.push(activityList);
        }

        /* Publish */
        if (templateData.activities.length > 0) {
            config.mediator.publish(config.MEDIATOR.ACTIVITY, _.template(studentFeedTpl, templateData));
        }
    }


    /* Populate chat template with data and publish */
    hydrateChatTemplate(messages, direction, messageCount, remaining) {
        let moreMessagesAvailable = false,
            templateType = config.SINGLE_CHAT;
        const templateData = {
            templateType: 'chat',
            currentUser: this.user.user.id,
            messages: [],
        };


        // Is there multiple items?
        if (_.isArray(messages)) {
            templateData.messages = messages;
            templateType = config.BATCH_CHAT;
        } else {
            // single chat message
            templateData.messages.push(messages);
        }

        _.each(templateData.messages, (payload, index) => {
            templateData.messages[index].message = payload.message.replace(
                `@${this.user.user.nickname}`,
                `<mark>@${this.user.user.nickname}</mark>`
            );
        });

        /* Publish */
        if (templateData.messages.length > 0) {
            let renderedTemplate = _.template(studentFeedTpl, templateData);

            /* Wrap with a batch div so we can determine scroll position when published */
            if (templateType === config.BATCH_CHAT) {
                /* if the total message return is less than 20 assume there are no more items to load*/
                if (remaining && remaining > 0) {
                    moreMessagesAvailable = true;
                }

                renderedTemplate = `<div class="hubmsgs-batch">${renderedTemplate}</div>`;
            }

            config.mediator.publish(config.MEDIATOR.CHAT.MESSAGES.DEFAULT,
                renderedTemplate, direction, templateType, messageCount, moreMessagesAvailable);
        } else {
            /* empty */
            config.mediator.publish(config.MEDIATOR.CHAT.MESSAGES.DEFAULT, false);
        }
    }


    /* Send a message */
    sendMessage(message) {
        const event = {
            type: 'chat',
            payload: {},
        };

        if (message && message !== '') {
            event.payload.message = message;
            this.connection.send(JSON.stringify(event));

            return true;
        }

        return false;
    }
}
