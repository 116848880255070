/**
* A widget to manage a list of strings.
* Initially made for course releases tasks.
*
* See admin.shared.field-list for the basic html needed for this
* widget to work properly.
*/
import _ from 'lodash';
import pickerTpl from '../../templates/media-picker.html';
import rowsTpl from '../../templates/media-picker-row.html';

export default class MediaPicker {
    /**
     * @param element jQuery|string jQuery object or css selector for the div containing the widget.
     */
    constructor(element, options) {
        if (element instanceof jQuery) {
            this.$el = element;
        } else {
            this.$el = $(element);
        }

        const me = this;
        this._options = options || {};
        this.render = function() {
            const baseHtml = _.template(pickerTpl, {});
            this.$el.html(baseHtml);
        };

        this.search = $.proxy(function($form) {
            /* eslint-disable no-param-reassign */
            $form = $form.clone();
            if (me._options.intakeId) {
                $form.append(`<input type="hidden" name="intake_id" value="${me._options.intakeId}" />`);
            }
            this.$el.find('tbody:eq(0)').html('<tr><td colspan="6" class="center">Loading...</td></tr>');
            $.get('/media/search', $form.serialize())
                .done((data) => {
                    me._results = data.payload;
                    const templateFn = _.template(rowsTpl);
                    me.$el.find('tbody:eq(0)').html(templateFn({ results: data.payload, currentMedia: me._currentMedia }));
                    me.bindEventHandlers();
                })
                .fail(() => {
                    me.$el.find('tbody').html('<tr><td colspan="6" class="center">Search failed</td></tr>');
                });
        }, this);

        this.bindEventHandlers = $.proxy(function() {
            this.$el.find('button[data-role="media-trigger"]').on('click', function(event) {
                event.preventDefault();
                const callback = me._options[$(this).data('action')];
                if (typeof callback === 'function') {
                    const media = _.filter(me._results, { id: parseInt($(this).data('media-id')) })[0];
                    callback(media);
                }

                if ($(this).data('action') === 'add') {
                    $(this).html('remove')
                        .removeClass('btn-success')
                        .addClass('btn-danger')
                        .data('action', 'remove');
                } else {
                    $(this).html('add')
                        .removeClass('btn-danger')
                        .addClass('btn-success')
                        .data('action', 'add');
                }
            });
        }, this);


        this.render();
        this.$el.find('form').on('submit', function(event) {
            event.preventDefault();
            $.proxy(me.search, me)($(this));
        });
        this.bindEventHandlers();
    }
    refresh(currentMedia) {
        this._currentMedia = currentMedia;
        this.search(this.$el.find('form:eq(0)'));
    }
}
