export default class MediaSuggest {
    constructor(element, index) {
        if (element instanceof jQuery) {
            this.$el = element;
        } else {
            this.$el = $(element);
        }

        this.index = index;

        const me = this;
        me.createResultsList(me);

        const resultsListElement = me.$el
            .parent('div')
            .find(`#acid-${me.index}-autocomplete-list`);

        element.focusout(() => {
            setTimeout(() => {
                resultsListElement.empty();
                resultsListElement.removeClass('active');
            }, 200);
        });

        element.on('keyup', function() {
            const meElement = $(this);
            $.get('/media/search', {
                'q' : $(this).val(),
                'language' : $(this).attr('data-language'),
            })
                .done((data) => {
                    resultsListElement.empty();
                    resultsListElement.addClass('active');

                    if (data.payload.length === 0) {
                        resultsListElement.removeClass('active');
                    } else {
                        data.payload.forEach((element) => {
                            resultsListElement.append(
                                `<div class='item' data-id='${element.id}'>
                                    <div class='item-id'>${element.id}</div>
                                    <div class='item-name'>${element.name}</div>
                                </div>`
                            );
                        });
                    }

                    resultsListElement.find('.item').each(function() {
                        $(this).on('click', function() {
                            meElement.val($(this).data('id'));
                            meElement.value = $(this).data('id');
                            resultsListElement.empty();
                            resultsListElement.removeClass('active');
                        });
                    });
                })
                .fail(() => {
                    console.error('Failed getting media items.');
                });
        });
    }

    createResultsList() {
        const listElement = document.createElement('div');
        listElement.setAttribute('id', `acid-${this.index}-autocomplete-list`);
        listElement.setAttribute('class', 'autocomplete-items');
        this.$el[0].parentNode.appendChild(listElement);
    }
}
