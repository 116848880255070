/*

 Name:           AjaxSetup
 Created:        19 June 2017
 Author:         Thijs
 Description:    Setup Ajax with CRSF token

 */

export default class AjaxBootstrap {
    constructor() {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            },
        });
    }
}
