/*

    Name:           Common
    Created:        16 June 2014
    Author:         Toby Evans
    Description:    Common, application wide methods. Not to included
                    to any other module

*/

export default class Selects {
    $dropdowns = null

    constructor() {
        this.toggle = this.toggle.bind(this);
        this.enter = this.enter.bind(this);
        this.leave = this.leave.bind(this);
        this.change = this.change.bind(this);
        this.change = this.change.bind(this);
        this.select = this.select.bind(this);
        this.toggleTree = this.toggleTree.bind(this);
        this.input = this.input.bind(this);

        this.$dropdowns = $('.dropdown');

        this.$dropdowns.on('click.selects', this.toggle)
            .on('mouseover.selects', this.enter)
            .on('mouseleave.selects', this.leave)
            .on('close.selects', this.change)
            .on('change.selects', this.change);

        this.$dropdowns.find('.option-label').on('click', this.select);
        this.$dropdowns.find('.icon-sarrow-right').on('click', this.toggleTree);

        this.$dropdowns.filter('.text').find('.input').on('input', this.input);

        console.log('Selects Started');
    }

    /* Open / Close  the dropdown */
    toggle(event) {
        event.stopPropagation();
        const { currentTarget } = event;

        // close other dropdowns
        this.$dropdowns.not(currentTarget).each(() => {
            if (this.timer) {
                clearTimeout(this.timer);
            }
            $(currentTarget).removeClass('open');
        });

        if ($(currentTarget).toggleClass('open').hasClass('open')) {
            const $select = $(currentTarget);
            $('body').off('click.selects').on('click.selects', (event) => {
                if (!$select.is(event.currentTarget) && $select.has(event.currentTarget).length === 0) {
                    $select.trigger('close.selects');
                }
            });
        } else {
            $('.open', currentTarget).removeClass('open');
            $('body').off('click.selects');
        }
    }

    change({ currentTarget }) {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        $(currentTarget).removeClass('open');
    }

    enter() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    leave(event) {
        if ($(event.currentTarget).hasClass('open')) {
            const $this = $(event.currentTarget);
            this.timer = setTimeout(() => {
                $this.trigger('click.selects');
            }, 2000);
        }
    }

    toggleTree(event) {
        event.stopPropagation();
        event.preventDefault();

        $(this).parent().toggleClass('open');

        return false;
    }

    select(event) {
        event.stopPropagation();

        const label = $(event.currentTarget).text();
        const value = $(event.currentTarget).parent('.option').data('value');
        const $select = $(event.currentTarget).parents('.dropdown');

        // return false if no value is set.
        if (value === undefined) {
            return false;
        }

        $select.find('.input').val(value);

        $select.attr('data-placeholder', label)
            .trigger('change');
    }

    input(event) {
        // search through options & hide none matching
        $(event.currentTarget).parent().find('.option').removeClass('hidden');

        if (!$(event.currentTarget).parent().hasClass('open')) {
            $(event.currentTarget).trigger('click');
        }

        if (event.value) {
            const $found = $(event.currentTarget).parent()
                .find('.option')
                .addClass('hidden')
                .filter(`[data-value*="${this.value}"]`)
                .removeClass('hidden');

            console.log('input', $found);
        }
    }
}
