import _ from 'lodash';
import config from '../common/config';
import Selects from '../common/selects';
import { summernoteConfig } from '../common/summernote';

// jQuery UI Widgets - Forcing in here to prevent tag-it error
import 'jquery-ui';
import '../vendor/jqueryui/core';
import '../vendor/jqueryui/autocomplete';
import '../vendor/jqueryui/datepicker';
import '../vendor/jqueryui/draggable';
import '../vendor/jqueryui/droppable';
import '../vendor/jqueryui/menu';
import '../vendor/jqueryui/mouse';
import '../vendor/jqueryui/position';
import '../vendor/jqueryui/sortable';
import '../vendor/jqueryui/widget';

import 'tag-it/js/tag-it';

export default class MediaUpload {
    $form = $('.upload-form');
    $progress = $('.progress');
    $fileInput = null;
    $fileName = null;
    fileInput = null;
    mediaUploadOptions = {
        maxTags: 5,
        tagMenuOffset: 0,
        heartbeatInterval: 30000,
    };

    constructor(mediaUploadOptions) {
        console.log('mediaUpload Started!');

        if (mediaUploadOptions) {
            $.extend(this.mediaUploadOptions, mediaUploadOptions);
        }

        this.assignMediaTagsInit();
        // start the text editor
        $('textarea[role="wysiwyg"]').each((index, el) => {
            $(el).summernote(summernoteConfig);
        });

        // start the dropdowns
        new Selects();

        // cache objects
        this.$fileName = this.$form.find('.filename');
        this.$fileInput = this.$form.find('.file');
        this.fileInput = this.$fileInput.get(0);

        this.$posterInput = this.$form.find('.poster-file');
        this.posterInput = this.$posterInput.get(0);

        // initialize events
        this.$form.on('submit', this.submit.bind(this));
        this.$form.find('.btn-cancel').on('click', () => {
            history.back();
        });
        this.$fileInput.on('change', this.chooseFile.bind(this));
        /* Listend for purpose changes*/
        $('#purposeSelectList .option-label').on('click.selects', this.listenForPurposeChange.bind(this));

        console.log($('#purposeSelectList .option-label'));
    }

    loadAudio(file) {
        return new Promise((resolve, reject) => {
            const audioObject = URL.createObjectURL(file);
            const audio = new Audio(audioObject);
            audio.preload = 'metadata';
            audio.onerror = reject;
            // why doesn't this return metadata
            audio.canplaythrough = resolve(audio);
        });
    }

    assignMediaTagsInit() {
        const $mediaTags = $('#mediaTags');
        let tags = [];

        if ($mediaTags.data('tags')) {
            tags = $mediaTags.data('tags').split(',');
        }

        $('#selectMediaTags').on('focus', '.ui-autocomplete-input', () => {
            $('#taggingHelper').addClass('is-active');
        });

        /* Tags */
        $mediaTags.tagit({
            animate: false,
            availableTags: tags,
            allowSpaces: true,
            tagLimit: this.maxTags,
            placeholderText: 'Enter a tag...',
            afterTagAdded: this.assignMediaTagsUpdateHelperText.bind(this),
            afterTagRemoved: this.assignMediaTagsUpdateHelperText.bind(this),
            autocomplete: {
                appendTo: $('#selectMediaTags'),
                open: () => {
                    /* Stupid Jquery UI */
                    $('.ui-autocomplete').css({
                        top: $('#selectMediaTags .tagit').outerHeight() - 1,
                        left: this.mediaUploadOptions.tagMenuOffset,
                        width: 'auto',
                    });
                },
            },
        });

        $(window).on('resize', _.debounce(() => {
            $('.ui-autocomplete').hide();
        }, 50));
    }

    assignMediaTagsUpdateHelperText() {
        const count = $('#selectMediaTags .tagit-choice').length;
        const $tagInput = $('.ui-autocomplete-input');
        const $taggingHelper = $('#taggingHelper');
        const tagsHelpers = {
            matcher: 'Start typing and we\'ll try and find existing tags',
            approachingMax: `Remember ${this.mediaUploadOptions.maxTags} tags max!`,
            maxReached: 'Great! That\'s all your tags!',
        };

        if (count === this.mediaUploadOptions.maxTags) {
            $taggingHelper.text(tagsHelpers.maxReached).addClass('is-active');
            $tagInput.addClass('is-hidden');
        } else {
            $tagInput.removeClass('is-hidden');

            if (count === 0) {
                $taggingHelper.text(tagsHelpers.matcher).addClass('is-active');
            } else if (count < 2) {
                $taggingHelper.removeClass('is-active');
            } else if (count > 2) {
                $taggingHelper.text(tagsHelpers.approachingMax).addClass('is-active');
            }
        }
    }

    listenForPurposeChange(event) {
        const $currentTarget = $(event.currentTarget).parent('li');

        if ($currentTarget.length) {
            const newPurpose = $currentTarget.data('value');

            if (newPurpose === config.UPLOAD_MEDIALIBRARY) {
                this.$form.removeClass('is-assessment');
                $('#courseIdSelect').show();
            } else {
                this.$form.addClass('is-assessment');
                $('#courseIdSelect').hide();
            }
        }
    }

    chooseFile() {
        console.log(this.fileInput.files[0]);
        this.$fileName.text(this.fileInput.files[0].name);
        this.$form.find('.or, .external').hide();
    }

    error(msg) {
        // show message
        config.mediator.publish('alert', msg, 'error');

        // reset form disabled elements
        this.$fileInput.find('+ .label').attr('for', 'file');
        $('.btn-publish').removeAttr('disabled').trigger('revert.actionable');
    }


    submit(event) {
        event.preventDefault();
        let media,
            media_type;
        const $externalUrlInput = $('[name="external_url"]');
        const path = (this.fileInput.files.length > 0) ? 'upload'
            : ($externalUrlInput.val().length > 0) ? 'embed'
                : null;
        const youtubeVimeo = /(?:(vimeo).com)|(?:(youtube).com)/g;
        const params = {
            'name': $('input[name="name"]').val(),
            'description': ($('.note-editable').length > 0) ? $('.note-editable')[0].innerHTML : $('textarea[name="description"]').val(),
            'language': ($('[name="language"]').is('input')) ? $('[name="language"]').val() : $('[name="language"]').children('option:selected').val(),
            'purpose': ($('[name="purpose"]').is('input')) ? $('[name="purpose"]').val() : $('[name="purpose"]').children('option:selected').val(),
            'course_id': ($(this)[0] === this) ? $('input[name="course_id"]').val() : $(this).find('[name="course_id"]').val(),
            'tags': $('[name="tags"]').val(),
            'next_media_id': $('#next_media_id').val(),
            'category_id': $('#category').val(),
        };

        if (this.posterInput && this.posterInput.files[0]) {
            params.poster_size = this.posterInput.files[0].size;
            params.poster_name = this.posterInput.files[0].name;
            params.poster_mimetype = this.posterInput.files[0].type;
        }

        if (path === 'upload') {
            /* jslint camelcase: false */
            params.upload_size = this.fileInput.files[0].size;
            params.upload_name = this.fileInput.files[0].name;
            params.upload_mimetype = this.fileInput.files[0].type;

            if (params.upload_mimetype.includes('audio')) {
                media_type = 'audio';
                const file = this.fileInput.files[0];

                this.loadAudio(file).then((data) => {
                    media = data;
                });
            }

            if (params.upload_mimetype === '') {
                params.upload_mimetype = 'application/octet-stream';
            }
        } else if (path === 'embed') {
            if (_.isNull($externalUrlInput.val().match(youtubeVimeo))) {
                config.mediator.publish('alert', 'Sorry we can only accept youtube or vimeo videos', 'error');

                return false;
            }
            params.external_url = $externalUrlInput.val();
        } else {
            config.mediator.publish('alert', 'Please provide your media.', 'error');

            return false;
        }

        if (params.name === '') {
            config.mediator.publish('alert', 'Please provide a title.', 'error');

            return false;
        }

        if (params.description === '') {
            config.mediator.publish('alert', 'Please provide a description.', 'error');

            return false;
        }

        if (params.language === '') {
            config.mediator.publish('alert', 'Please provide a language code.', 'error');

            return false;
        }

        if (params.category_id === '') {
            config.mediator.publish('alert', 'Please provide a category id.', 'error');

            return false;
        }

        if (params.purpose === '') {
            config.mediator.publish('alert', 'Please provide a purpose.', 'error');

            return false;
        }

        const $button = $('.btn-publish');
        $button.find('.text').html($button.data('action-text'));
        $button.data('action-text', 'Uploading&hellip;');
        $button.attr('disabled', 'disabled');

        // disable the file selector
        $('[for=file]').removeAttr('for');
        this.heartBeat.init();

        // if loading audio, check for existence of the media object
        // ugly workaround to receiving duration
        // ideally use async/await but babel woes with webpack
        if (!media && media_type === 'audio') {
            setTimeout(() => {
                params.duration = parseInt(media.duration);
                this.processUpload(params);
            }, 1000);
        } else {
            this.processUpload(params);
        }

        return false;
    }

    processUpload(params) {
        $.ajax({
            url: '/media/upload/link',
            method: 'post',
            dataType: 'json',
            data: params,
            cache: false,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            },
            success: (response) => {
                this.submitSuccess(response);
                if (!response.success) {
                    console.error(response.message);
                }
            },
            error: () => {
                this.heartBeat.stopBeating();
                config.mediator.publish('alert', 'Unable to start the upload, please review your submission and try again.', 'error');
            },
        });
    }

    /* Maintain session */
    heartBeat = {
        init: () => {
            console.log('heartbeat init');

            /* Init heartbeat */
            this.heart = setInterval(() => {
                $.ajax({
                    url: config.ENDPOINTS.HEARTBEAT,
                    success: (heartbeatResponse) => {
                        if (!heartbeatResponse.success) {
                            this.stopBeating();
                            config.mediator.publish('alert', 'There was a problem with your upload, please try again', 'error');
                        }
                    },
                    error: () => {
                        this.heartBeat.stopBeating();
                        config.mediator.publish('alert', 'The server could not be reached, please try again.', 'error');
                    },
                });
            }, this.mediaUploadOptions.heartbeatInterval);
        },

        stopBeating: () => {
            if (this.heartBeat.heart) {
                clearInterval(this.heartBeat.heart);
            }
        },
    }

    /* jslint camelcase: false */
    submitSuccess(createResponse) {
        /* Validation passed */
        if (createResponse.success) {
            // Global media id variable
            const mediaId = createResponse.payload.media_id;
            const $button = $('.btn-publish').attr('type', 'button');

            $button.find('.text').html($button.data('action-text'));

            $button.data('action-text', 'Processing&hellip;');
            const foo = () => {
                if (createResponse.payload.poster_url) {
                    return this.storeFile(createResponse.payload.poster_url, this.posterInput.files[0], mediaId);
                }
            };
            $.when(foo()).then(() => {
                if (createResponse.payload.storage_service === 'amazon-s3') {
                    this.$progress
                        .attr('max', this.fileInput.files[0].size)
                        .removeClass('hidden')
                        .removeAttr('value');

                    let lastProgress = 0;
                    const that = this;
                    const xhr = new XMLHttpRequest();
                    xhr.open('PUT', createResponse.payload.url, true);
                    xhr.setRequestHeader('Content-type', 'application/octet-stream');
                    xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
                    xhr.onload = function() {
                        if (xhr.readyState === xhr.DONE) {
                            if (xhr.status === 200) {
                                setTimeout(() => {
                                    that.processMedia(mediaId);
                                }, 2000);
                            } else {
                                that.heartBeat.stopBeating();
                                config.mediator.publish(
                                    'alert',
                                    'Your upload has failed, please refresh the page and try again.',
                                    'error'
                                );
                            }
                        }
                    };
                    xhr.upload.onprogress = function(pe) {
                        if (pe.lengthComputable) {
                            const percentUploaded = Math.floor((pe.loaded * 100) / pe.total);
                            if (percentUploaded > lastProgress) {
                                lastProgress = percentUploaded;
                                that.$progress.val(pe.loaded).data('uploaded', lastProgress);
                            }
                        }
                    };
                    xhr.send(this.fileInput.files[0]);
                } else if (createResponse.payload.storage_service === 'boxView') {
                    const formData = new FormData();
                    formData.append('file', this.fileInput.files[0]);

                    // Upload file to Mindlab that will go to Box View
                    $.ajax({
                        url: createResponse.payload.upload_url,
                        type: 'POST',
                        data: formData,
                        cache: false,
                        processData: false,
                        contentType: false,
                        xhr: () => {
                            const xhr = new window.XMLHttpRequest();
                            xhr.upload.addEventListener('progress', (evt) => {
                                if (evt.lengthComputable) {
                                    const percentUploaded = Math.floor((evt.loaded * 100) / evt.total);
                                    let lastProgress = 0;

                                    if (percentUploaded > lastProgress) {
                                        lastProgress = percentUploaded;
                                        this.$progress.val(evt.loaded).data('uploaded', lastProgress);
                                    }
                                }
                            }, false);

                            return xhr;
                        },
                    })
                        .done(() => {
                            // Check if object exists
                            setTimeout(() => {
                                this.checkBoxViewMedia(mediaId);
                            }, 5000);
                        })
                        .fail(() => {
                            this.heartBeat.stopBeating();
                            config.mediator.publish(
                                'alert',
                                'Your upload has failed, please refresh the page and try again.',
                                'error'
                            );
                        });

                    this.$progress
                        .attr('max', this.fileInput.files[0].size)
                        .removeClass('hidden')
                        .removeAttr('value');
                } else {
                    // If YouTube or Vimeo
                    this.showMediaAsPublished(createResponse);
                }
            });

            /* Validation failed */
        } else {
            this.heartBeat.stopBeating();
            config.mediator.publish('alert', 'Unable to start the upload, please review your submission and try again.', 'error');
        }
    }

    storeFile(url, file, mediaId, onload = () => {}) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            let waiting = true;
            xhr.open('PUT', url, true);
            xhr.setRequestHeader('Content-type', file.type ?? 'application/octet-stream');
            xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
            xhr.onload = () => {
                console.log('stop waiting');
                waiting = false;

                return onload();
            };
            xhr.upload.onprogress = (pe) => {
                if (pe.lengthComputable) {
                    const percentUploaded = Math.floor((pe.loaded * 100) / pe.total);
                    let lastProgress = 0;

                    if (percentUploaded > lastProgress) {
                        lastProgress = percentUploaded;
                        this.$progress.val(pe.loaded).data('uploaded', lastProgress);
                    }
                }
            };
            xhr.send(file);

            /*
             * @todo solve this real crappy hack.
             */
            const sleepNow = (delay) => new Promise((resolve) => setTimeout(resolve, delay));
            sleepNow(5000); // if it 5 seconds to finish uploading.
        });
    }

    checkBoxViewMedia(mediaId) {
        const $button = $('.btn-publish');
        $button.find('.text').html($button.data('action-text'));

        this.heartBeat.stopBeating();

        $.ajax({
            url: `/media/${mediaId}/upload/boxview/process`,
            method: 'post',
            dataType: 'json',
            data: {},
            success: (publishResponse) => {
                if (publishResponse.success) {
                    this.showMediaAsPublished(publishResponse);

                    return;
                }

                setTimeout(() => {
                    this.checkBoxViewMedia(mediaId);
                }, 5000);
            },
            error: (publishResponse) => {
                if (publishResponse.payload.abort) {
                    $('.btn-publish .text').html('Failed');
                    config.mediator.publish('alert', 'Unable to process your uploaded media, please refresh the page and try again.', 'error');

                    return;
                }
            },
        });
    }

    processMedia(mediaId) {
        const $button = $('.btn-publish');
        $button.find('.text').html($button.data('action-text'));

        this.heartBeat.stopBeating();

        $.ajax({
            url: `/media/${mediaId}/upload/aws/process`,
            method: 'post',
            dataType: 'json',
            data: {},
            success: (publishResponse) => {
                if (publishResponse.success) {
                    this.showMediaAsPublished(publishResponse);
                } else {
                    if (publishResponse.payload.abort) {
                        $('.btn-publish .text').html('Failed');
                        config.mediator.publish('alert', 'Your upload could not be encoded, please refresh the page and try again.', 'error');

                        return;
                    }

                    setTimeout(() => {
                        this.processMedia(mediaId);
                    }, 2000);
                }
            },
            error: (err) => {
                console.log(err);
                config.mediator.publish('alert', 'Unable to process your uploaded media, please refresh the page and try again.', 'error');
            },
        });
    }

    showMediaAsPublished(processResponse) {
        $('.btn-publish .text').html('Completed');
        let message = 'Your upload is now complete and you are being redirected...';

        if (processResponse.payload.message) {
            message = processResponse.payload.message;
        }

        config.mediator.publish('alert', message, 'success');

        if (processResponse.payload.return_url) {
            setTimeout(() => {
                window.location = processResponse.payload.return_url;
            }, 3000);
        } else if (this.$form.data('return-url')) {
            setTimeout(() => {
                window.location = this.$form.data('return-url');
            }, 3000);
        } else {
            setTimeout(() => {
                window.location = '/';
            }, 3000);
        }
    }
}
