import Summernote from 'summernote/dist/summernote';

const toolbarOptions = [
    // [groupName, [list of button]]
    ['style', ['style', 'bold', 'italic', 'underline', 'clear']],
    ['font', ['strikethrough']],
    ['fontname', ['fontname']],
    ['fontsize', ['fontsize']],
    ['color', ['color']],
    ['para', ['ul', 'ol', 'paragraph']],
    ['height', ['height']],
    ['insert', ['link', 'picture', 'video']],
    ['view', ['fullscreen', 'codeview', 'help']],
];

export const summernoteConfig = {
    toolbar: toolbarOptions,
    codeviewFilter: false,
    codeviewIframeFilter: true,
    disableDragAndDrop: true,
};

export default Summernote;
