/*

    Name:           Populate Chat Stream
    Created:        29 May 2014
    Author:         Aarron Painter
    Description:    Insert chat history

*/
import config from './config';

export default class ChatStream {
    totalMessagesShown = 0
    moreToLoad = false
    $messageInput = {}
    $wrapper = {}
    $messageButton = {}
    $streamEmpty = {}

    /* Initiate the chat stream */
    constructor($chatWrapper, $chatList, $chatMessageInput, $chatMessageButton) {
        const streamHelpers = `<div class="chat-notify"/><div class="chat-loader" /><div class="chat-empty">${config.CHAT_EMPTY}</div>`;

        if ((!$chatWrapper || !$chatWrapper.length) ||
            (!$chatList || !$chatList.length)) {
            console.warn('Failed to enable chatstream : No chat wrapper or chat list');

            return false;
        }

        if ((!$chatMessageInput || !$chatMessageInput.length) ||
            (!$chatMessageButton || !$chatMessageButton.length)) {
            console.warn('Failed to enable chatstream : No message input or button');

            return false;
        }

        $chatWrapper.prepend($(streamHelpers));

        /* Cache the chat inputs so we can disable if needed */
        this.$messageInput = $chatMessageInput;
        this.$wrapper = $chatWrapper;
        this.$messageButton = $chatMessageButton;
        this.$streamEmpty = this.$wrapper.find('.chat-empty');

        this.handleMessagesScrolling($chatWrapper, $chatList);
        this.bindSendMessageEvents($chatMessageInput, $chatMessageButton);

        /* Listen for new chat messages */
        config.mediator.subscribe(config.MEDIATOR.CHAT.MESSAGES.DEFAULT,
            (rMessageItems, rDirection, rType, rMessageCount, rMoreMessagesAvailable) => {
                /* Is there any chat history ? */
                if (!rMessageItems) {
                    this.$streamEmpty.addClass('is-active');

                    return false;
                }
                this.$streamEmpty.removeClass('is-active');


                const chatInstance = {
                    $chatWrapper: $chatWrapper,
                    $chatList: $chatList,
                    messageItems: rMessageItems,
                    direction: rDirection,
                    type: rType,
                    messageCount: rMessageCount,
                    moreMessagesAvailable: rMoreMessagesAvailable,
                };

                this.populateStreamEntry(chatInstance);
            });

        /* Not Supported */
        config.mediator.subscribe(config.MEDIATOR.CONNECTION.NOT_SUPPORTED, (message) => {
            this.toggleNotification(message);
        });

        /* Listen for a dead server */
        config.mediator.subscribe(config.MEDIATOR.CONNECTION.DEAD, (message) => {
            this.toggleNotification(message);
        });

        /* Listen for an alive server */
        config.mediator.subscribe(config.MEDIATOR.CONNECTION.ALIVE, () => {
            this.toggleNotification();
        });

        /* Is the user banned ?*/
        config.mediator.subscribe(config.MEDIATOR.AUTH.IS_BANNED, (message) => {
            this.toggleNotification(message);
        });
    }

    /**
      * Toggle Notification Area
      */
    toggleNotification(message) {
        /* Disables notification area if no message is passed */

        if (message) {
            this.$messageInput.attr('disabled', true);
            this.$messageButton.addClass('disabled');
            this.$wrapper.addClass('disabled').find('.chat-notify').addClass('is-active').text(message);
        } else {
            this.$messageInput.removeAttr('disabled');
            this.$messageButton.removeClass('disabled');
            this.$wrapper.removeClass('disabled').find('.chat-notify').removeClass('is-active').text('');
        }
    }


    /**
      * Populate Popup with Stream Entries
      * @param {chatInstance}
      *      $chatWrapper            - The chat list wrapper Element
      *      $chatList               - The chat list Element
      *      messageItems            - The Chat templates
      *      direction               - the direction forward / back
      *      type                    - Single chat item or a batch of items
      *      messageCount            - The total of items in this instance
      *      moreMessagesAvailable   - Are there more messages available
      */
    populateStreamEntry(chatInstance) {
        if ((!chatInstance.$chatWrapper || !chatInstance.$chatWrapper.length) ||
            (!chatInstance.$chatList || !chatInstance.$chatList.length)) {
            console.warn('Failed to populate chat stream');

            return false;
        }

        const isBottom = (chatInstance.$chatWrapper.height() + chatInstance.$chatWrapper[0].scrollTop) === chatInstance.$chatWrapper[0].scrollHeight;

        if (!chatInstance.messageItems) {
            // no items returned
            chatInstance.$chatWrapper.removeClass('has-more-content');

            return false;
        }

        /* Update total message */
        if (chatInstance.messageCount) {
            this.totalMessagesShown += chatInstance.messageCount;
        }

        /* More items to load? */
        if (chatInstance.type === config.BATCH_CHAT) {
            this.moreToLoad = chatInstance.moreMessagesAvailable;
        }

        /* Where do we add this message (or batch of messages) ?*/
        if (chatInstance.direction && chatInstance.direction === config.CHAT_DIR_BACK) {
            const $streamItems = $(chatInstance.messageItems);

            /* prepend past items and set the scroll position to be the
            height of the result */
            chatInstance.$chatList.prepend($streamItems);
            chatInstance.$chatWrapper[0].scrollTop = $streamItems.height();
        } else {
            chatInstance.$chatList.append(chatInstance.messageItems);
        }

        // We are at the bottom of the message pane, so lets keep the user here
        if (isBottom) {
            chatInstance.$chatWrapper.animate({
                scrollTop: chatInstance.$chatWrapper[0].scrollHeight,
            }, 0);
        }
    }


    /**
     * Prevents page from scrolling if the user scrolls to the bottom
     * or top of the div; triggers load of historic chat
     */
    handleMessagesScrolling($chatWrapper, $chatList) {
        if ((!$chatWrapper || !$chatWrapper.length) ||
            (!$chatList || !$chatList.length)) {
            console.warn('Failed to enable chat stream scrolling');

            return false;
        }

        // $chatWrapper.on('DOMMouseScroll mousewheel scroll', function(ev) {
        $chatWrapper.on('mousewheel scroll', (ev) => {
            const scrollTop = $chatWrapper.scrollTop;
            const scrollHeight = $chatWrapper.scrollHeight;
            const height = $chatWrapper.height();
            let delta = 0,
                up = 0;

            const prevent = () => {
                ev.stopPropagation();
                ev.preventDefault();
                ev.returnValue = false;

                return false;
            };

            if (ev.type === 'scroll') {
                if (scrollTop === 0) {
                    if ((this.totalMessagesShown >= config.BATCHLOADCOUNT) && this.moreToLoad) {
                        console.log('LOAD MORE CONTENT');
                        $chatWrapper.addClass('has-more-content');
                        this.loadFeeds(config.CHAT_DIR_BACK, $chatList.find('.media').first().data('id'));
                    } else {
                        $chatWrapper.removeClass('has-more-content');
                    }
                }
            } else {
                delta = ev.originalEvent.wheelDelta;
                up = delta > 0;

                if (!up && -delta > scrollHeight - height - scrollTop) {
                    $chatWrapper.scrollTop(scrollHeight);

                    return prevent();
                } else if (up && delta > scrollTop) {
                    $chatWrapper.scrollTop(0);

                    return prevent();
                }
            }
        });
    }

    /**
     * Helper method to request a message
     */
    loadFeeds(direction, messageId) {
        config.mediator.publish(config.MEDIATOR.CHAT.MESSAGES.GETRANGE, messageId, direction);
    }


    /**
     * Bind keydown and click events for sending a message
     */
    bindSendMessageEvents($chatMessageInput, $chatMessageButton) {
        /* Bind key toggles */
        $chatMessageInput.keydown((event) => {
            if (event.keyCode === 13 && !event.shiftKey) {
                // prevent default behavior
                event.preventDefault();

                /* Send Message */
                this.sendMessage($chatMessageInput);
            }
        });

        /* Bind send button */
        $chatMessageButton.on('click', (event) => {
            event.preventDefault();
            this.sendMessage($chatMessageInput);
        });
    }


    /**
      * Send the message
      */
    sendMessage($chatMessageInput) {
        const message = $chatMessageInput.val();

        if (message.trim().length === 0) {
            return false;
        }

        config.mediator.publish(config.MEDIATOR.CHAT.SEND, message);
        $chatMessageInput.val('');
    }
}
