/*

    Name:           Modals
    Created:        25 Nov 2015
    Author:         Bettina
    Description:    Modals functionality

*/


export default class Modals {
    constructor() {
        $('button[data-dismiss="modal"]').on('click', () => {
            $('.modal').addClass('hidden');
        });

        $('button[data-action="gradebook.grades.approve"]').on('click', () => {
            $('div[data-modal="gradebook.grades.approve"]').removeClass('hidden');
            $('div[data-modal="gradebook.grades.approve"]').addClass('show');
        });

        $('button[data-action="gradebook.grades.reject"]').on('click', () => {
            $('div[data-modal="gradebook.grades.reject"]').removeClass('hidden');
            $('div[data-modal="gradebook.grades.reject"]').addClass('show');
        });

        console.log('Modals started');
    }
}
