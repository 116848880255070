/*

 Name:          Mindlab Admin
 Created:       27 May 2014
 Author:        PixelFusion
 Description:   RequireJs configuration for Mindlab Admin

                Via grunt; outputs to /project/public/admin/css/admin-shared.css

 */
import AjaxBootstrap from './common/ajaxBootstrap';
import ChatStream from './common/chatstream';
import config from './common/config';
import ContentLink from './admin/widgets/content-link';
import jQuery from 'jquery';
import List from './admin/widgets/list';
import MediaLink from './admin/widgets/media-link';
import MediaSuggest from './admin/widgets/media-suggest';
import Mediator from 'mediator-js';
import MediaUpload from './views/media-upload';
import Modals from './common/modals';
import moment from 'moment';
import SocketServer from './common/socket-server';
import SubmissionFilter from './admin/views/submissions-filter';
import {summernoteConfig} from './common/summernote';

// jQuery Plugins
import 'bootstrap/dist/js/bootstrap';
import '../bower_components/eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min';
import '../bower_components/hideshowpassword/hideShowPassword.min';
import '../bower_components/startbootstrap/templates/sb-admin-2/js/plugins/metisMenu/metisMenu';
import '../bower_components/startbootstrap/templates/sb-admin-2/js/sb-admin-2';
import '../../../node_modules/bootstrap-select/dist/js/bootstrap-select';

import 'foundation-sites/js/foundation/foundation';
import 'foundation-sites/js/foundation/foundation.alert';
import 'foundation-sites/js/foundation/foundation.tab';
import 'foundation-sites/js/foundation/foundation.reveal';

// import './vendor/preview';
// jQuery UI Widgets
import 'jquery-ui';
import './vendor/jqueryui/core';
import './vendor/jqueryui/autocomplete';
import './vendor/jqueryui/datepicker';
import './vendor/jqueryui/draggable';
import './vendor/jqueryui/droppable';
import './vendor/jqueryui/menu';
import './vendor/jqueryui/mouse';
import './vendor/jqueryui/position';
import './vendor/jqueryui/sortable';
import './vendor/jqueryui/widget';
import 'tag-it/js/tag-it';

// Polyfills
import 'promise-polyfill';
import './vendor/modernizr.custom.min.js';

// CSS
import '../sass/admin.scss';

window.$ = jQuery;
window.jquery = jQuery;
window.jQuery = jQuery;

const doc = document;
const bootScript = doc.getElementById('pfMindlabAdmin');

config.WSERVER = bootScript.getAttribute('data-config');

$(doc).ready(() => {
    /* Setup global pub/sub */
    config.mediator = new Mediator();

    /* Ajax Setup */
    new AjaxBootstrap();

    /* Dashboard only. TODO - move these out into their own views */
    if ($('#adminMsgs').length) {
        /* Setup the chat stream - listening to events */
        new ChatStream(
            $('#adminMsgs'),
            $('#adminMsgList'),
            $('#adminMsg'),
            $('#adminMsgBtn')
        );

        /* Start the socket server */
        new SocketServer();
    }

    $('div[role="list"]').each((index, el) => {
        /* eslint-disable-next-line */
        new List($(el));
    });

    $('[role="media-link"]').each((index, el) => {
        /* eslint-disable-next-line */
        new MediaLink($(el));
    });

    $('[role="content-link"]').each((index, el) => {
        /* eslint-disable-next-line */
        new ContentLink($(el));
    });

    /* Suggest Media */
    $('[role="suggest-media"]').each((index, el) => {
        new MediaSuggest($(el), index);
    });

    $('[role="date-picker"]').each((index, el) => {
        $(el).datetimepicker({
            pickTime: false,
            defaultDate: moment(),
        });

        // limit other datepicker min date
        if ($(el).data('set-min')) {
            $(el).on('dp.change', (event) => {
                $($(el).data('set-min'))
                    .data('DateTimePicker')
                    .setMinDate(event.date);
            });
        }

        // limit other datepicker max date
        if ($(el).data('set-max')) {
            $(el).on('dp.change', (event) => {
                $($(el).data('set-max'))
                    .data('DateTimePicker')
                    .setMaxDate(event.date);
            });
        }
    });

    $('[role="datetime-picker"]').datetimepicker();
    $('input[data-toggle="popover"], textarea[data-toggle="popover"]').popover({
        trigger: 'focus',
    });

    /* Uploads */
    if ($('.upload-form').length) {
        // eslint-disable-next-line no-new
        new MediaUpload({tagMenuOffset: 15});
    }

    $('[role="filters"]').each(() => {
        new SubmissionFilter();
    });

    /* Modals */
    new Modals();

    /* Tags */
    if ($('#tags').length) {
        const tags = $('#tags').data('tags').split(',');
        $('#tags').tagit({
            availableTags: tags,
            allowSpaces: true,
            tagLimit: 10,
            animate: false,
            placeholderText: 'Enter a tag...',
            autocomplete: {
                appendTo: $('#selectMediaTags'),
                open: () => {
                    $('.ui-autocomplete').css({
                        top: $('#selectMediaTags .tagit').outerHeight() - 1,
                        left: 15,
                        width: 'auto',
                    });
                },
            },
        });
    }

    /* Consolidate Tags */
    if ($('#consolidateTags').length) {
        const selectedTags = {};
        const $tagInput = $('#tagIds');
        const $tagValues = $('#selectedTags');
        const $mergeTagsBtn = $('#mergeTagsBtn');

        $('#consolidateTags').on('click', '.ctag', (event) => {
            const target = event.currentTarget;
            const tagId = target.value;
            const tagValue = target.getAttribute('data-value');
            const tagIds = [];
            let tagValues = '';

            $(target).parents('tr').toggleClass('selected');

            if (selectedTags[tagId]) {
                delete selectedTags[tagId];
            } else {
                selectedTags[tagId] = tagValue;
            }

            for (const index in selectedTags) {
                tagIds.push(index);
                tagValues += `<span class="tagit-choice">${selectedTags[index]}</span>`;
            }

            if (tagValues) {
                $tagInput.val(tagIds.join(','));
                $tagValues.html(tagValues);
                $mergeTagsBtn.removeAttr('disabled');
            } else {
                $tagInput.val('');
                $tagValues.html('None selected.');
                $mergeTagsBtn.attr('disabled', 'disabled');
            }
        });
    }

    /* Login */
    $('#password').hideShowPassword(false, true);

    // send delete requests via DELETE
    $('.table').on('click', '[role="delete"]', (event) => {
        event.preventDefault();
        if (confirm('Are you sure?')) {
            // $.post($(event.currentTarget).attr('href'), {_method: 'delete'}, function() {
            //     $(event.currentTarget).closest('tr').remove();
            // });
            $(
                `<form method="post" action="${$(event.currentTarget).attr(
                    'href'
                )}">`
            )
                .append('<input type="hidden" name="_method" value="delete" />')
                .append(
                    `<input name="_token" type="hidden" value="${$(
                        'meta[name="csrf-token"]'
                    ).attr('content')}">`
                )
                .appendTo('body')
                .submit();
        }
    });

    /**
     * Update primary course release
     */
    $('.table').on('click', '[role="set-primary-course-release"]', (event) => {
        event.preventDefault();
        if (confirm('Are you sure?')) {
            $(
                `<form method="post" action="${$(event.currentTarget).attr(
                    'href'
                )}">`
            )
                .append('<input type="hidden" name="_method" value="post" />')
                .append(
                    `<input name="_token" type="hidden" value="${$(
                        'meta[name="csrf-token"]'
                    ).attr('content')}">`
                )
                .appendTo('body')
                .submit();
        }
    });

    // Intake duplication
    $('[role="duplicate-intake"]').on('click', (event) => {
        event.preventDefault();
        const $modal = $('#duplicate-progress');
        $modal.find('.modal-footer button').attr('disabled', 'disabled');
        $modal
            .find('.modal-body')
            .html('The intake is being duplicated, please wait...');
        $modal.modal({
            backdrop: 'static',
            keyboard: false,
        });
        $.post($(event.currentTarget).data('href'))
            .done((data) => {
                const intervalId = setInterval(() => {
                    $.get(data.ping)
                        .done((response) => {
                            if (
                                !response.state ||
                                (response.state &&
                                    response.state.state === 'finished')
                            ) {
                                clearInterval(intervalId);
                                if (response.state) {
                                    window.location = response.state.intake_url;
                                } else {
                                    window.location.reload();
                                }

                                $modal.modal('hide');
                            } else if (response.success === false) {
                                $modal
                                    .find('.modal-body')
                                    .html('Intake duplication failed.');
                                $modal
                                    .find('.modal-footer button')
                                    .removeAttr('disabled');
                                clearInterval(intervalId);
                            }
                        })
                        .fail(() => {
                            clearInterval(intervalId);
                            $modal
                                .find('.modal-body')
                                .html('Server error ocurred.');
                            $modal
                                .find('.modal-footer button')
                                .removeAttr('disabled');
                        });
                }, 1000);
            })
            .fail(() => null);
    });

    $('.table').on('click', '[role="surrogate"]', () => {
        return confirm(
            'This will sign you out the administration client and into the student portal as the selected user.\n\nAre you sure?'
        );
    });

    /* Apply WYSIWYG */
    $('textarea[role="wysiwyg"]').each((index, el) => {
        $(el).summernote(summernoteConfig);
    });

    /* Media modals */
    $('a[data-target="#media-modal"]').on('click', (event) => {
        event.preventDefault();

        if (!event.currentTarget.href) {
            return false;
        }

        $.get(event.currentTarget.href).done((data) => {
            if (data) {
                $('#media-modal-content').empty().append(data);
                $('#media-modal').modal('show');
            }
        });
    });

    $('#media-modal').on('hidden.bs.modal', () => {
        $('#media-modal-content').empty();
    });

    // apply data table on listings
    $('[role="data-table"]').each((i, e) => {
        const colIndex = $(e).data('sortby') || 0;
        const ajax = $(e).data('ajaxUrl') || null;
        if (ajax) {
            $(e).dataTable({
                processing: true,
                serverSide: true,
                ajax,
            });
        } else {
            $(e).dataTable({order: [colIndex, 'asc']});
        }
    });

    // Make tabs work
    $('ul[role="tablist"]').tab();

    /* Side Menu*/
    $('#side-menu').metisMenu();
    $(window).bind('load resize', () => {
        const width = window.innerWidth > 0 ? window.innerWidth : screen.width;

        if (width < 768) {
            $('div.sidebar-collapse').addClass('collapse');
        } else {
            $('div.sidebar-collapse').removeClass('collapse');
        }
    });
});
