/**
 * A widget to add media elements to a table.
 */
import _ from 'lodash';
import dialogTpl from '../../templates/media-link-dialog.html';
import MediaPicker from '../../admin/widgets/media-picker';
import rowTpl from '../../templates/media-link-row.html';
import 'datatables.net-dt';
import 'datatables.net-rowreorder-dt';

/**
 * @param element jQuery|string jQuery object or css selector for the div containing the widget.
 */
export default class MediaLink {
    constructor(element) {
        if (element instanceof jQuery) {
            this.$el = element;
        } else {
            this.$el = $(element);
        }

        this.$updateOrder = this.$el.find('button[data-action="updateorder"]');
        this.$updateOrderLoading = this.$el.find('i.loading');

        this.$table = this.$el.find('table:eq(0)');
        this.$dataTable = this.$table.DataTable({
            searching: false,
            paging: false,
            ordering: false,
            info: false,
            rowReorder: {
                update: false,
                selector: 'tr td:not(:last-child)',
            },
        });

        this.$dataTable.on('row-reorder', (e, diff, edit) => {
            this.$updateOrder.prop('disabled', false);
        });

        this.courseReleaseId = this.$el.data('course-release-id');
        this.categoryId = this.$el.data('category-id');
        this.$addMedia = this.$el.find('button[data-action="pickmedia"]');
        this.fieldName = this.$el.data('field-name'); // hidden input field name
        this.intakeId = this.$el.data('intake-id');
        this.mediaDelete = this.$el.data('media-delete'); // link to delete media

        const dialog = $(_.template(dialogTpl)());
        this.modal = dialog.appendTo('body');
        this.mediaPicker = new MediaPicker(this.modal.find('.modal-body'), {
            intakeId: this.intakeId,
            add: (media) => {
                const blankRow = this.$table.find('tr.media-link-blank-state');
                if (blankRow.length > 0) {
                    blankRow.remove();
                }
                if (
                    this.$table.find(`tr[data-media-id="${media.id}"]`).length >
                    0
                ) {
                    return;
                }

                const newRow = this.$dataTable.row
                    .add([
                        media.id,
                        `<input type="hidden" name="${this.fieldName}[new][]" value="${media.id}" /><a href="/media/${media.id}/edit">${media.name}</a>`,
                        media.description,
                        '<button data-toggle="media-action" data-action="remove" data-state="current" class="btn btn-danger btn-xs">remove</button>',
                    ])
                    .draw()
                    .node();

                $(newRow)
                    .addClass('media-item')
                    .attr('data-media-id', media.id);

                this.bindRowEvents($(newRow));

                // Update media with category id
                $.post(`/media/${media.id}/updateMediaCategory`, {
                    category_id: this.categoryId,
                })
                    .fail(() => {
                        alert(
                            'There was an error updating the media with category.'
                        );
                        console.error(
                            'There was an error updating the media with category.'
                        );
                    })
                    .always(() => {
                        console.log('Media updated with category.');
                    });
            },
            remove: (media) => {
                console.log('Remove callback: ', media);
                const $row = this.$table.find(
                    `tbody tr[data-media-id="${media.id}"]`
                );
                this.deleteRow($row);
            },
        });

        this.$addMedia.on('click', (event) => {
            event.preventDefault();
            // pass in the current media ids to the picker so
            // we can show a remove button there
            const ids = this.$table
                .find('tbody tr[data-media-id]')
                .map((el) => $(el).data('media-id'))
                .get();
            this.modal.modal('show');
            this.modal.removeClass('hidden');
            this.mediaPicker.refresh(ids);
        });

        this.$updateOrder.on('click', (event) => {
            event.preventDefault();
            this.$updateOrderLoading.show();
            const dataList = this.$table
                .find('.media-item')
                .map(function () {
                    return $(this).data('media-id');
                })
                .get();

            $.post(
                `/course_releases/${this.courseReleaseId}/updateMediaOrder`,
                { [this.fieldName]: dataList }
            )
                .fail(() => {
                    alert('There was an error saving the order.');
                    console.error('There was an error saving the media order.');
                })
                .always(() => {
                    this.$updateOrder.prop('disabled', true);
                    this.$updateOrderLoading.hide();
                });
        });

        // bind events for existing media rows
        this.$table.find('tbody tr[data-media-id]').each((index, el) => {
            this.bindRowEvents($(el));
        });
    }

    /**
     * Removes a row. Mark to remove if media is attached.
     * (attached means existing on the DB)
     *
     * @param $row jQuery object of the row to remove.
     */
    deleteRow($row) {
        if ($row.data('state') !== 'new') {
            // if the media we're deleting is currently attached, mark for removal
            this.$table.before(
                `<input type="hidden" name="${
                    this.fieldName
                }[remove][]" value="${$row.data('media-id')}" />`
            );
        }
        $row.fadeOut(() => {
            $row.remove();
            if (this.$table.find('tbody tr[data-media-id]').length === 0) {
                // removed all rows, display empty state
                this.$table
                    .find('tbody:eq(0)')
                    .append(
                        [
                            '<tr class="media-link-blank-state">',
                            '    <td class="center" colspan="4">There are no more media objects.</td>',
                            '</tr>',
                        ].join('')
                    );
            }
        });
    }

    /**
     * Binds remove button event on a row.
     *
     * @param $row jQuery object of the row.
     */
    bindRowEvents($row) {
        $row.on('click', 'button[data-action="remove"]', (event) => {
            event.preventDefault();
            this.deleteRow($(event.currentTarget).parents('tr:eq(0)'));
        });
    }
}
