/*

    Name:           Common
    Created:        16 May 2014
    Author:         Aarron Painter
    Description:    Application wide data


*/

export default {

    /* MQ's obtained via SASS */
    MEDIAQUERIES: {},

    /* Identify the current module */
    BOOTMODULE: {},

    /* Web Socket Details */
    WSERVER: {},

    /* Endpoints for Ajax Requets */
    ENDPOINTS: {
        HEARTBEAT:             '/app/keep-alive',
        COURSE_RELEASE_MEDIA:  '/course/release/media.json',
        NOTIFICATIONS_AS_READ: '/notifications/mark-read', // POST
        MESSAGES_LOAD_NEXT:    '/student/hub/messages.json',
        SCHOOL_NAMES:          '/school/results.json',
        SETUP:                 '/welcome',
        SETUP_USERNAME:        '/welcome/validate',
    },

    /* Navigation Menu Hide and Shows */
    NAV_HUB_OPEN:   'hub:open',
    NAV_HUB_CLOSE:  'hub:close',
    NAV_MENU_CLOSE: 'dropdown:close',


    /* RequireJS Page Types */
    APP_VIEW:    'app',
    SPLASH_VIEW: 'splash',

    /* Socket Server Constants */
    BATCHLOADCOUNT:       20, // how many items to load per batch request
    ONLINE_STATUS_MANY:   ' Students',
    ONLINE_STATUS_SINGLE: ' Student',
    MAX_NOTIFICATIONS:    5,

    /* Is this a batch of items or a single item ?*/
    BATCH_CHAT:  'batch',
    SINGLE_CHAT: 'single',

    /* Direciton constants for knowing where to append, prepend content */
    CHAT_DIR_FORWARD: 'forward',
    CHAT_DIR_BACK:    'back',
    CHAT_EMPTY:       'There is currently no chat history.',

    /* Mentions */
    SESSION_UNSEEN_MENTIONS: 'unseenMentions',
    MENTION_RESET:           'reset',
    MENTION_ADD:             'add',
    MENTION_SET:             'set',

    /* FOLLOWERS SETS / UPDATES*/
    FOLLOWER_SET:        'set',
    FOLLOWER_DISCONNECT: 'disconnect',
    FOLLOWER_CONNECT:    'connect',

    /* UPLOAD PURPOSES */
    UPLOAD_MEDIALIBRARY: 'media_library',

    /* Activities */
    ACTIVITY_TERMS: {
        FOLLOWED:   ' started following ',
        UPLOADED:   ' uploaded a new ',
        FAVOURITED: ' favourited ',
        COMMENTED:  ' commented on ',
    },

    /* Global channels for Pub / Sub */
    MEDIATOR: {
        AUTH: {
            IS_BANNED: 'ws:auth:isbanned',
        },

        USERS: {
            ONLINE: 'ws:users:online',
        },

        NOTIFICATION:    'ws:users:notification',
        ACTIVITY:        'ws:users:activity',
        FOLLOWER_ONLINE: 'ws:follower:online',

        CONNECTION: {
            ALIVE:         'ws:connection:alive',
            DEAD:          'ws:connection:dead',
            NOT_SUPPORTED: 'ws:connection:notsupported',
        },

        CHAT: {
            SEND:     'ws:message:send',
            MESSAGES: {
                DEFAULT:  'ws:messages', // for a single chat message or handling of auth returned messages
                GETRANGE: 'ws:message:range', // A range of messages via xhr
                MENTION:  'ws:message:mention',
            },

        },
    },
};
