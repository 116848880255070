/**
 * A widget to add content to a table.
 */
import _ from 'lodash';
import ContentPicker from '../../admin/widgets/content-picker';
import dialogTpl from '../../templates/content-link-dialog.html';
import 'datatables.net-dt';
import 'datatables.net-rowreorder-dt';

/**
  * @param element jQuery|string jQuery object or css selector for the div containing the widget.
  */
export default class ContentLink {
    constructor(element) {
        if (element instanceof jQuery) {
            this.$el = element;
        } else {
            this.$el = $(element);
        }

        this.$updateOrder = this.$el.find('button[data-action="updateorder"]');
        this.$updateOrderLoading = this.$el.find('i.loading');

        this.contentType = this.$el.data('content-type');
        // pass model route
        this.contentRoute = this.$el.data('content-route');
        this.$table = this.$el.find('table:eq(0)');
        this.$dataTable = this.$table.DataTable({
            searching: false,
            paging: false,
            ordering: false,
            info: false,
            rowReorder: {
                update: false,
                selector: 'tr td:not(:last-child)',
            },
        });

        this.$dataTable.on('row-reorder', (e, diff, edit) => {
            this.$updateOrder.prop('disabled', false);
        });

        this.courseReleaseId = this.$el.data('course-release-id');
        this.language = this.$el.data('content-language');
        this.$addContent = this.$el.find('button[data-action="pickcontent"]');
        this.fieldName = this.$el.data('field-name'); // hidden input field name
        this.delete = this.$el.data('content-delete'); // link to delete content

        // @todo parse more information to the template
        const dialog = $(_.template(dialogTpl)());

        this.modal = dialog.appendTo('body');
        this.contentPicker = new ContentPicker(
            this.modal.find('.modal-body'),
            {
                language: this.language,
                contentType: this.contentType,
                contentRoute: this.contentRoute,
                fields: this.displayColumnFields(this.contentType),
                add: (content) => {
                    const blankRow = this.$table.find('tr.content-link-blank-state');
                    if (blankRow.length > 0) {
                        blankRow.remove();
                    }
                    if (
                        this.$table.find(`tr[data-content-id="${content.id}"]`).length >
                        0
                    ) {
                        return;
                    }

                    const newRow = this.$dataTable.row
                        .add([
                            ...[content.id],
                            ...this.displayColumns(this.contentType, content),
                            ...['<button data-toggle="content-action" data-action="remove" data-state="current" class="btn btn-danger btn-xs">remove</button>'],
                        ])
                        .draw()
                        .node();

                    $(newRow)
                        .addClass('content-item')
                        .attr('data-content-id', content.id);

                    this.bindRowEvents($(newRow));
                },
                remove: (content) => {
                    const $row = this.$table.find(
                        `tbody tr[data-content-id="${content.id}"]`
                    );
                    this.deleteRow($row);
                },
            }
        );

        this.$addContent.on('click', (event) => {
            event.preventDefault();
            // pass in the current content ids to the picker so
            // we can show a remove button there
            const ids = this.$table
                .find('tbody tr[data-content-id]')
                .map((el) => $(el).data('content-id'))
                .get();
            this.modal.modal('show');
            this.modal.removeClass('hidden');
            this.contentPicker.refresh(ids);
        });

        // bind events for existing content rows
        this.$table.find('tbody tr[data-content-id]').each((index, el) => {
            this.bindRowEvents($(el));
        });
    }

    /**
      * Removes a row. Mark to remove if content is attached.
      * (attached means existing on the DB)
      *
      * @param $row jQuery object of the row to remove.
      */
    deleteRow($row) {
        $row.fadeOut(() => {
            $row.remove();
            if (this.$table.find('tbody tr[data-content-id]').length === 0) {
                // removed all rows, display empty state
                this.$table
                    .find('tbody:eq(0)')
                    .append(
                        [
                            '<tr class="content-link-blank-state">',
                            '    <td class="center" colspan="4">There is no more content.</td>',
                            '</tr>',
                        ].join('')
                    );
            }
        });
    }

    /**
      * Binds remove button event on a row.
      *
      * @param $row jQuery object of the row.
      */
    bindRowEvents($row) {
        $row.on('click', 'button[data-action="remove"]', (event) => {
            event.preventDefault();
            this.deleteRow($(event.currentTarget).parents('tr:eq(0)'));
        });
    }

    displayColumnFields(contentType) {
        switch (contentType) {
            case 'media':
                return [
                    {
                        field: 'id',
                        title: 'ID',
                    },
                    {
                        field: 'name',
                        title: 'Name',
                    },
                    {
                        field: 'description',
                        title: 'Description',
                    },
                    {
                        field: 'media_type',
                        title: 'Type',
                    },
                ];
            default:
                return [
                    {
                        field: 'id',
                        title: 'ID',
                    },
                    {
                        field: 'title',
                        title: 'Title',
                    },
                    {
                        field: 'description',
                        title: 'Description',
                    },
                ];
        }
    }

    /**
     * Display specific columns based on content type
     */
    displayColumns(contentType, content) {
        switch (contentType) {
            case 'media':
                return [
                    `<input type="hidden" name="${this.fieldName}[]" value="${content.id}" /><a href="/${this.contentRoute}}/${content.id}/edit">${content.name}</a>`,
                    content.description,
                    content.media_type,
                ];
            default:
                return [
                    `<input type="hidden" name="${this.fieldName}[]" value="${content.id}" /><a href="/${this.contentRoute}}/${content.id}/edit">${content.title}</a>`,
                    content.description,
                ];
        }
    }
}
